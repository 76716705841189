import { waitForDOMContentLoaded } from "./async-utils";
import configs from "./configs";
import { getLocale } from "./i18n";
// Node these should be synchronized with the top of shared.scss
const DEFAULT_ACTION_COLOR = "#FF3464";
const DEFAULT_ACTION_COLOR_LIGHT = "#FF74A4";

let DEFAULT_COLORS = {
  "action-color": DEFAULT_ACTION_COLOR,
  "action-label-color": DEFAULT_ACTION_COLOR,
  "action-color-disabled": DEFAULT_ACTION_COLOR_LIGHT,
  "action-color-highlight": DEFAULT_ACTION_COLOR_LIGHT,
  "action-text-color": "#FFFFFF",
  "action-subtitle-color": "#F0F0F0",
  "notice-background-color": "#2F80ED",
  "notice-text-color": "#FFFFFF",
  "favorited-color": "#FFC000",
  "nametag-color": "#000000",
  "nametag-volume-color": "#7ED320",
  "nametag-text-color": "#FFFFFF",
  "nametag-border-color": "#7ED320",
  "nametag-border-color-raised-hand": "#FFCD74"
};

console.log("------------");
console.log(
  `USER LANG DETECTED => ${navigator.language.toLowerCase()} ${navigator.language.toLowerCase().indexOf("fr")}`
);
console.log("------------");

window.SAT = {};
window.SAT.Utils = {
  locale: navigator.language.toLowerCase().indexOf("fr") > -1 ? "fr" : "en"
};
window.SAT.themes = { customThemeChecked: false, forceDOMLoaded: false };

const themeQs = new URLSearchParams(location.search);
const themeDefaultRoomId = configs.feature("default_room_id");
const themeFoundHubID =
  window.APP != null && window.APP.hub != null && window.APP.hub.hub_id != null
    ? window.APP.hub.hub_id
    : document.location.pathname.substring(1).split("/")[0];

const themeTempHubID =
  themeQs.get("hub_id") ||
  (document.location.pathname === "/" && themeDefaultRoomId ? themeDefaultRoomId : themeFoundHubID);

const fetchRoomThemes = async hubID => {
  return new Promise(resolve => {
    try {
      const themesURL = `${process.env.INJECTION_URL}/hubs/theme/${hubID}`;
      // console.log("fetchRoomThemes", themesURL);
      fetch(themesURL, { method: "get" })
        .then(function(body) {
          return body.text();
        })
        .catch(function(err) {
          console.warn(err);
          return resolve(false);
        })
        .then(function(data) {
          // console.log(data);
          if (data) {
            const results = JSON.parse(data);
            const hasCustomTheme = results.hasCustomTheme;
            const hasGlobalTheme = results.hasGlobalCustomTheme;
            window.SAT.useCustomEntry = results.useCustomEntry;
            window.SAT.useDynamicCustomEntry = results.useDynamicCustomEntry;
            window.SAT.useCustomSpacesMenu = results.useCustomSpacesMenu;
            if (hasCustomTheme) {
              window.SAT.themes.customTheme = results.themeColors;
              //console.log("room theme configs found ");
              //console.log(results.themeColors);
              return resolve(true);
            } else {
              if (hasGlobalTheme) {
                window.SAT.themes.customTheme = results.globalThemeColors.themeColors;
                //console.log("global theme configs found ");
                //console.log(results.globalThemeColors.themeColors);
                return resolve(true);
              }
              //console.log("NO theme found whatsoever");
              return resolve(false);
            }
          } else {
            console.warn("NO DATA FOUND ON RESPONSE BODY");
            return resolve(false);
          }
        })
        .catch(function(err) {
          console.warn(err);
          return resolve(false);
        });
    } catch (err) {
      console.warn(err);
      return resolve(false);
    }
  });
};

function getThemeColor(name) {
  let customThemeVariable = null;
  if (window.SAT.themes && window.SAT.themes.roomThemeDefined && window.SAT.themes.customTheme) {
    if (window.SAT.themes.customTheme?.[name]) {
      customThemeVariable = window.SAT.themes.customTheme[name];
    }
  }
  if (customThemeVariable) {
    return customThemeVariable;
  }

  const themeId = window.APP?.store?.state?.preferences?.theme;

  const theme =
    (themeId && configs.APP_CONFIG?.theme?.themes?.find(theme => theme.id === themeId)) ||
    configs.APP_CONFIG?.theme?.themes?.find(theme => theme.default === true);
  if (theme?.variables?.[name]) {
    return theme.variables[name];
  }

  return DEFAULT_COLORS[name];
}

function activateTheme() {
  const actionColor = getThemeColor("action-color");
  const actionHoverColor = getThemeColor("action-color-highlight");

  if (document.querySelector("#rounded-text-button")) {
    // NOTE, using the object-based {} setAttribute variant in a-frame
    // seems to not work in Firefox here -- the entities with the mixins are not
    // updated.
    document
      .querySelector("#rounded-text-button")
      .setAttribute(
        "text-button",
        `textHoverColor: ${actionHoverColor}; textColor: ${actionColor}; backgroundColor: #fff; backgroundHoverColor: #aaa;`
      );

    document
      .querySelector("#rounded-button")
      .setAttribute(
        "text-button",
        `textHoverColor: ${actionHoverColor}; textColor: ${actionColor}; backgroundColor: #fff; backgroundHoverColor: #aaa;`
      );

    document
      .querySelector("#rounded-text-action-button")
      .setAttribute(
        "text-button",
        `textHoverColor: #fff; textColor: #fff; backgroundColor: ${actionColor}; backgroundHoverColor: #aaa`
      );

    document
      .querySelector("#rounded-action-button")
      .setAttribute(
        "text-button",
        `textHoverColor: #fff; textColor: #fff; backgroundColor: ${actionColor}; backgroundHoverColor: #aaa`
      );
  }
}

const onStoreChanged = (function() {
  let themeId;
  return function onStoreChanged() {
    const newThemeId = window.APP?.store?.state?.preferences?.theme;
    if (themeId !== newThemeId) {
      themeId = newThemeId;
      activateTheme();
    }
  };
})();

function onColorsChanged(data) {
  DEFAULT_COLORS = data.detail;
  console.log("onColorsChanged", DEFAULT_COLORS);
  activateTheme();
  const event = new CustomEvent("themeShifterChanged", { detail: "themeShifterChanged" });
  window?.APP?.store?.dispatchEvent(event);

  const cameraEvent = new CustomEvent("labelChange", { detail: `${DEFAULT_COLORS["action-color"]}` });
  window?.dispatchEvent(cameraEvent);
}

const applyThemeAssets = () => {
  if (document.body && document.body.classList) {
    if (configs.APP_CONFIG && configs.APP_CONFIG.theme && configs.APP_CONFIG.theme["dark-theme"]) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.add("light-theme");
    }
  }
  activateTheme();
  window.APP?.store?.addEventListener("statechanged", onStoreChanged);
  window.APP?.store?.addEventListener("constchanged", onColorsChanged);
};

if (!window.SAT.themes.roomThemeDefined && themeTempHubID) {
  fetchRoomThemes(themeTempHubID)
    .then(res => {
      // console.log(res);
      window.SAT.themes.customThemeChecked = true;
      window.SAT.themes.roomThemeDefined = res;
      applyThemeAssets();
    })
    .catch(() => {
      window.SAT.themes.customThemeChecked = true;
      window.SAT.themes.roomThemeDefined = false;
      applyThemeAssets();
    });
}

waitForDOMContentLoaded().then(() => {
  if (window.SAT.themes.customThemeChecked) {
    applyThemeAssets();
  } else {
    window.SAT.themes.forceDOMLoaded = true;
  }
});

function applyThemeToTextButton(el, highlighted) {
  el.setAttribute(
    "text-button",
    "backgroundColor",
    highlighted ? getThemeColor("action-color-highlight") : getThemeColor("action-color")
  );
  el.setAttribute(
    "text-button",
    "backgroundHoverColor",
    highlighted ? "#aaa" : getThemeColor("action-color-highlight")
  );
}

export { applyThemeToTextButton, getThemeColor };
